import { useState, useEffect } from "react";
import "../elements.css"

interface PostData {
    postId?: number;
    newsContent?: string;
    publisherName?: string;
    created_at?: string;
    publisherId?: string; // Include other fields as necessary
}

export default function AllNews() {
    const [postData, setPostData] = useState<PostData[]>([]);
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL+'/api/allNews';
        if (apiUrl) {
            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    setPostData(data);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } else {
            console.error("API URL is not defined");
        }
    }, [])
    const formatDate = (dateString: string) => {
        if (!dateString) return "";
        const date = new Date(dateString); // Convert string to Date object
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="newsList">
            {Array.isArray(postData) ? postData.map((post: PostData) => (
                <div className="news" key={post.postId}>
                    <p className="newsContent">{post.newsContent}</p>
                    <p className="newsFooter">{post.publisherName} @ {post.created_at && formatDate(post.created_at)}</p>
                </div>
            )) : <p>Brak aktualności. Sprawdź ponownie później.</p>}
        </div>
    );
}