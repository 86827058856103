
import { useState } from 'react';
import './main.css';
import AllNews from '../../news/allNews';
import DashboardTemp from '../DashboardLayout';

export default function Aktualnosci() {

  return (
    <DashboardTemp>
      <h2>WSZYSTKIE AKTUALNOŚCI</h2>
      <AllNews />
    </DashboardTemp>
  );
}
