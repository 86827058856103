'use client';
import { useState } from 'react';
import './main.css';
import DashboardTemp from '../DashboardLayout';
import ActiveVotes from './AktywneGlosowania';
import ClosedVotes from './ZamknieteGlosowania';


export default function Glosowanie() {

  return (
    <DashboardTemp>
      <h2>AKTYWNE GŁOSOWANIA / ANKIETY</h2>
      <ActiveVotes />

      <h2>ZAKOŃCZONE GŁOSOWANIA / ANKIETY</h2>
      <ClosedVotes />
    </DashboardTemp>
  );
}
