import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Welcome from './elements/Welcome';
import Login from './elements/Login';
import Dashboard from './elements/dashboard/Dashboard';
import Aktualnosci from './elements/dashboard/aktualnosci/Aktualnosci';
import Glosowanie from './elements/dashboard/glosowanie/Glosowanie';
import Error404 from './elements/404Error';
import Grupy from './elements/dashboard/grupy/page';
import Skladki from './elements/dashboard/skladki/page';
import Materialy from './elements/dashboard/materialy/Materialy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <Routes>
      <Route path='/' element={<Welcome />} />
      <Route path='/login' element={<Login />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/dashboard/aktualnosci' element={<Aktualnosci />}/>
      <Route path='/dashboard/glosowania' element={<Glosowanie />}/>
      <Route path='/dashboard/grupy' element={<Grupy />}/>
      <Route path='/dashboard/skladki' element={<Skladki />}/>
      <Route path='/dashboard/materialy' element={<Materialy />}/>

      <Route path='*' element={<Error404 />} />
    </Routes>
  </Router>
);