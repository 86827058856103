import { useEffect, useState } from 'react';
import './dashboardMain.css';
import LatestNews from '../news/latestNews';
import DashboardTemp from './DashboardLayout';

interface Meeting {
  spotkanieDate?: string,
  location?: string,
  description?: string,
  meetingLink?: string
}

export default function Dashboard() {
  const [closeMeet, setCloseMeet] = useState<Meeting>({});
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL+'/api/closestMeeting';
    if (apiUrl) {
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                setCloseMeet(data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    } else {
        console.error("API URL is not defined");
    }
  }, [])
  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString); // Convert string to Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear().toString(); // Get last two digits of the year
    const hour = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    return `${day}/${month}/${year} ${hour}:${minutes}`;
};
  return (
    <DashboardTemp>
      <h2>Witaj w panelu członka kółka OSINT Masters.</h2>
      <h2>OSTATNIE AKTUALNOŚCI</h2>
      <LatestNews />
      <h2>NAJBLIŻSZE SPOTKANIE</h2>
      <div className='currentMeeting'>
        {
          (closeMeet.spotkanieDate && closeMeet.location)
          &&
          <h3>{closeMeet.spotkanieDate && formatDate(closeMeet.spotkanieDate)} @ {closeMeet.location}</h3>
        }
        <p>{closeMeet.description}</p>
        {
          closeMeet.meetingLink &&
          <a href={closeMeet.meetingLink}>LINK DO SPOTKANIA</a>
        }
      </div>
    </DashboardTemp>
  );
}
