import { useEffect, useState } from 'react';
import './dashboardMain.css';
import LatestNews from '../news/latestNews';

export default function DashboardTemp({
    children,
  }: Readonly<{
    children: React.ReactNode;
  }>) {

    const [voteActive, setVoteActive] = useState(3);
    function mobileMenu() {
      if(document.querySelector('#hmLinksMenu span:nth-child(1)')?.classList.contains('active') || document.querySelector('#hmLinksMenu span:nth-child(2)')?.classList.contains('active') || document.querySelector('#hmLinksMenu span:nth-child(3)')?.classList.contains('active') || document.querySelector('#hmLinks')?.classList.contains('active')){
          document.querySelector('#hmLinksMenu span:nth-child(1)')?.classList.remove('active');
          document.querySelector('#hmLinksMenu span:nth-child(2)')?.classList.remove('active');
          document.querySelector('#hmLinksMenu span:nth-child(3)')?.classList.remove('active');
          document.querySelector('#hmLinks')?.classList.remove('active');
      } else {
          document.querySelector('#hmLinksMenu span:nth-child(1)')?.classList.add('active');
          document.querySelector('#hmLinksMenu span:nth-child(2)')?.classList.add('active');
          document.querySelector('#hmLinksMenu span:nth-child(3)')?.classList.add('active');
          document.querySelector('#hmLinks')?.classList.add('active');
      }
  }

  useEffect(() => {
    document.getElementById('hmLinksMenu')?.addEventListener('click', mobileMenu);
  });
  
  return (
    <main className="dashboardPage">
      
      <div className='dashNavi'>
      <h1>OSINT MASTERS</h1>
        <div className="dashNaviLinksDesktop">
          <a href='/dashboard'>HOME</a>
          <a href='/dashboard/aktualnosci'>AKTUALNOŚCI</a>
          <a href='/dashboard/glosowania'>GŁOSOWANIA/ANKIETY {voteActive > 0 && <span className='voteActive'>{voteActive}</span>}</a>
          <a href='/dashboard/grupy'>GRUPY</a>
          {/* <a href='/dashboard/skladki'>SKŁADKI</a> */}
          <a href='/dashboard/materialy'>MATERIAŁY</a>
          <a href='#' id='logout'>WYLOGUJ</a>
        </div>
        <div id='hmLinksMenu'>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div id="hmLinks">
          <div id='hmLinksBcg'>
            <a href='/dashboard'>HOME</a>
            <a href='/dashboard/aktualnosci'>AKTUALNOŚCI</a>
            <a href='/dashboard/glosowania'>GŁOSOWANIA/ANKIETY {voteActive > 0 && <span className='voteActive'>{voteActive}</span>}</a>
            <a href='/dashboard/grupy'>GRUPY</a>
            {/* <a href='/dashboard/skladki'>SKŁADKI</a> */}
            <a href='/dashboard/materialy '>MATERIAŁY</a>
            <a href='#' id='logout'>WYLOGUJ</a>
          </div>
        </div>
      </div>
      <div className='content'>
        {children}
      </div>
    </main>
  );
}