export default function Welcome() {
    return(
        <main className="mainPage">
            <h1>OSINT MASTERS</h1>
            <p>OSINT Masters to studenckie kółko naukowe działające w Akademii Ekonomiczno-Humanistycznej w Warszawie. Naszym celem jest zgłębianie i praktyczne zastosowanie metod Open Source Intelligence (OSINT) – czyli analizy i zbierania informacji z ogólnodostępnych źródeł. W ramach naszych działań organizujemy warsztaty, prelekcje oraz projekty badawcze, które pozwalają studentom rozwijać umiejętności analityczne, techniczne oraz strategiczne. OSINT Masters to idealne miejsce dla tych, którzy chcą poszerzyć swoją wiedzę o nowoczesnych narzędziach wywiadowczych i zdobyć cenne doświadczenie w praktycznym wykorzystaniu informacji.</p>
            <div className="mainPageRedirects">
                <a href="mailto:rekrutacja@osintmasters.pl?subject=Rekrutacja%20do%20OSINT%20Masters">DOŁĄCZ DO NAS</a>
                <a href="/login">ZALOGUJ SIĘ</a>
            </div>
        </main>
    )
}