'use client';
import { useEffect, useState } from 'react';
import './main.css';
import DashboardTemp from '../DashboardLayout';

interface Materials {
  id?: number;
  title?: string;
  videoURI?: string;
  videoPreviewURI?: string;
  publisherName?: string; // Include other fields as necessary
  created_at?: string; // Include other fields as necessary
}


export default function Materialy() {

  const [materials, setMaterials] = useState<Materials[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState({'videoURI': '', 'title': ''})
  const [visibleModal, setVisibleModal] = useState(false);
  useEffect(() => {
      const apiUrl = process.env.REACT_APP_API_URL+'/api/getMaterials';
      if (apiUrl) {
          fetch(apiUrl)
              .then(response => response.json())
              .then(data => {
                console.log(data);
                  setMaterials(data);
              })
              .catch(error => {
                  console.error("Error fetching data:", error);
              });
      } else {
          console.error("API URL is not defined");
      }
  }, [])

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = new Date(dateString); // Convert string to Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    return `${day}/${month}/${year}`;
};

  return (
    <DashboardTemp>
      <h2>MATERIAŁY Z ZAJĘĆ</h2>
      <div className='materialyList'>
        {Array.isArray(materials) ? materials.map((material: Materials) => (
              <div className="material" key={material.id} onClick={() => {
                if(material.videoURI && material.title)
                  setSelectedMaterial({'videoURI': material.videoURI, 'title': material.title})
                  setVisibleModal(true)
                }}>
                  <p className="materialTitle">{material.title}</p>
                  <p className="materialFooter">{material.publisherName} @ {material.created_at && formatDate(material.created_at)}</p>
              </div>
          )) : <p>Brak materiałów. Sprawdź ponownie później.</p>}
      </div>
      <div className='materialyModal' style={{visibility: visibleModal ? 'visible' : 'hidden'}}>
        <div className='materialyModalContent'>
          <span id='titleBar'><h2>{selectedMaterial && selectedMaterial.title}</h2><span id='closeModal' onClick={() => setVisibleModal(false)}>X</span></span>
          <iframe src={selectedMaterial.videoURI} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
      </div>
    </DashboardTemp>
  );
}