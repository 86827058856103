import { useEffect, useState } from "react";
import "../elements.css"

interface PostData {
    newsContent?: string;
    publisherName?: string;
    created_at?: string;
    publisherId?: string; // Include other fields as necessary
}

export default function LatestNews() {
    const [postData, setPostData] = useState<PostData>({});
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL+'/api/latestNews';
        if (apiUrl) {
            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    setPostData(data);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } else {
            console.error("API URL is not defined");
        }
    }, [])
    const formatDate = (dateString: string) => {
        if (!dateString) return "";
        const date = new Date(dateString); // Convert string to Date object
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };
    return (
        <div className="news">
            <p className="newsContent">{postData.newsContent}</p>
            <p className="newsFooter">{postData.publisherName} @ {postData.created_at && formatDate(postData.created_at)}</p>
        </div>
    )
}