'use client';
import { useEffect, useState } from 'react';
import './main.css';
import DashboardTemp from '../DashboardLayout';

interface Skladki {
  id: number;
  name: string;
  toPay: number;
  payed: number;
  date: string;
  deadline: string;
}

export default function Skladki() {

  const [skladki, setSkladki] = useState<Skladki[]>([])
    useEffect(() => {
        const simulateFetchSkladki = () => {
            const simulatedSkladki = [
              { id: 1, name: 'Domena + Hosting platformy', toPay: 10.00, payed: 10.01, date: "18/05/2024", deadline: "31/05/2024"},
              { id: 2, name: 'Sprzęt', toPay: 50.00, payed: 0, date: "10/05/2024", deadline: "05/06/2024"},
              { id: 1, name: 'Licencja', toPay: 100.00, payed: 100, date: "05/05/2024", deadline: "15/05/2024"},
            ];
            setSkladki(simulatedSkladki);
        };

        simulateFetchSkladki();
    }, []);

  return (
    <DashboardTemp>
      <h2>WYKAZ SKŁADEK</h2>
    <table className="skladkiList">
      <thead>
        <tr>
          <th>Nazwa</th>
          <th>Do zapłaty</th>
          <th>Zapłacono</th>
          <th>Data dodania</th>
          <th>Termin płatności</th>
          <th>Pozostało do zapłaty</th>
        </tr>
      </thead>
      <tbody>
        {skladki.map((skladka) => (
          <tr key={skladka.id} className={(skladka.toPay - skladka.payed < 0) ? "skladka-nadplata" : ((skladka.toPay - skladka.payed === 0) ? "skladka-zaplacone" : "skladka-niezaplacone")}>
            <td>{skladka.name}</td>
            <td>{skladka.toPay.toFixed(2)} zł</td>
            <td>{skladka.payed.toFixed(2)} zł</td>
            <td>{skladka.date}</td>
            <td>{skladka.deadline}</td>
            <td>{(skladka.toPay - skladka.payed < 0) ? ("NADPŁATA: "+(skladka.payed - skladka.toPay).toFixed(2)) : (skladka.toPay - skladka.payed).toFixed(2)} zł</td>
          </tr>
        ))}
      </tbody>
    </table>
    </DashboardTemp>
  );
}
