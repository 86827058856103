import { useState, useEffect } from "react";
import "../../elements.css"
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

interface Votes {
    id: number;
    title: string;
    type: string;
    voted: boolean;
}

export default function ClosedVotes() {
    const [votes, setVotes] = useState<Votes[]>([]);

    useEffect(() => {
        // Simulating fetching data from an API
        const fetchedVotes: Votes[] = [
            { id: 1, title: "Masz jakieś propozycje co zmienić?", type: "form", voted: false },
            { id: 2, title: "Jak oceniasz zajęcia?", type: "vote", voted: false },
            { id: 3, title: "Co byś zmienił podczas zajęć z kółka?", type: "form", voted: true }
        ];
        setVotes(fetchedVotes);
    }, []);

    return (
        <div className="votesList">
            {votes.map((vote: Votes) => (
                <a href="#" className={"vote-closed vote-true"} key={vote.id} {...(vote.voted ? { 'disabled': 'true' } : {})}>
                    <p className="voteTitle">{vote.title} ({vote.type === 'form' && "FORMULARZ" || vote.type === 'vote' && "GŁOSOWANIE"})</p>
                    <p className={'voteStatus-'+vote.voted}>{vote.voted ? <IoMdCheckmark /> : <IoMdClose />}</p>
                </a>
                ))}
        </div>
    );
}