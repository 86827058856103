import { useEffect, useState } from "react"


interface Groups {
    id: number;
    name: string;
    member1: string;
    member2: string;
    member3: string;
    member4: string | null;
    member5: string | null;
}

export default function Groups() {

    const [groups, setGroups] = useState<Groups[]>([])
    useEffect(() => {
        const simulateFetchGroups = () => {
            const simulatedGroups = [
                { id: 1, name: 'Group Alpha', member1: 'Alice', member2: 'Janusz', member3: 'Charlie', member4: 'Dave', member5: 'Eve' },
                { id: 2, name: 'Group Beta', member1: 'Faith', member2: 'George', member3: 'Harry', member4: 'Ivy', member5: 'Jack' },
                { id: 3, name: 'Group Gamma', member1: 'Karen', member2: 'Leo', member3: 'Mia', member4: null, member5: null }
            ];
            setGroups(simulatedGroups);
        };

        simulateFetchGroups();
    }, []);

    return (
        <div className="groupsList">
            {groups.map((group: Groups) => (
                <div className="groups" key={group.id}>
                    <p className="groupName">{group.name}</p>
                    <ul className="membersList">
                        <li>{group.member1}</li>
                        <li>{group.member2}</li>
                        <li>{group.member3}</li>
                        {group.member4 && <li>{group.member4}</li>}
                        {group.member5 && <li>{group.member5}</li>}
                    </ul>
                </div>
            ))}
        </div>
    )
}