import { useState } from 'react';
import './login.css';

export default function Login() {
  const [loginData, setLoginData] = useState({
    login: "",
    password: ""
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setLoginData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    console.log('Submitting:', loginData);
    // Here you can add your logic to handle login, e.g., making an API call
  };

  return (
    <main className="mainPage">
      <h1>ZALOGUJ SIĘ</h1>
      <form onSubmit={handleSubmit}>
        <input 
          type='email' 
          name='login'
          value={loginData.login}
          onChange={handleInputChange}
          placeholder='Login'
          required
        />
        <input 
          type='password' 
          name='password'
          value={loginData.password}
          onChange={handleInputChange}
          placeholder='Hasło'
          required
        />
        <button type='submit'>Login</button>
      </form>
    </main>
  );
}
